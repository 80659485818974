import SiteConfig from '@/services/SiteConfig'

export default {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
   *
   * currency: It formats numbers into the locale currency format
   * @param {number|String} number
   * @returns {string} Formatted currency
   */
  currency (number) {
    const checked = _getNumber(number)
    return checked !== null ? checked.toLocaleString(SiteConfig.lang, SiteConfig.currencyConfig) : number
  },

  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
   *
   * float: It formats numbers to local string
   * @param {number|String} number
   * @param {number} fractionDigits
   * @param {Boolean} cutDecimals
   * @returns {number|String} Formatted float
   */
  float (number, fractionDigits = 1, cutDecimals = true) {
    const checked = _getNumber(number)
    if (checked !== null) {
      return cutDecimals && !(checked % 1) ? checked : checked.toLocaleString(SiteConfig.lang, { minimumFractionDigits: fractionDigits })
    }
    return number
  }
}

/**
 * _getNumber: validator function for filters
 * @param value
 * @returns {Null|Number} value is number or not
 */
const _getNumber = (value) => {
  // type check (isNaN is not type safe)
  if (typeof value !== 'number') {
    // try to parse and check again
    let parsed = parseFloat(value)
    if (isNaN(parsed) && SiteConfig.devMode) {
      console.warn('The given value is not a number ' + value)
      return null
    }
    return parsed
  }
  return value
}
