const MAX_RETRIES = 3
const DELAY = 1000
const STATUS_REGEX = /^(408|429|5\d{2})$/
const DESCRIPTION_REGEX = /(Failed to fetch|Load failed|NetworkError)/
const API_V2_REGEX = /api\.vicampo\.[^\s/]+\/v2/

const DISABLED_EXTRACTION = /\/(view|helpful\/vote)$/

class RequestHelper {
  static async sendGetRequest (url) {
    try {
      return await _fetchWithRetry(url, _getBaseConfig('GET'))
    } catch (e) {
      _handleError(e)
    }
  }

  static async sendPostRequest (url, data) {
    const config = _getBaseConfig('POST')
    try {
      return await _fetchWithRetry(url, { ...config, body: JSON.stringify(data) }, 1)
    } catch (e) {
      _handleError(e)
    }
  }

  static async sendPatchRequest (url, data) {
    const config = _getBaseConfig('PATCH')
    try {
      return await _fetchWithRetry(url, { ...config, body: JSON.stringify(data) })
    } catch (e) {
      _handleError(e)
    }
  }

  static async sendDeleteRequest (url) {
    try {
      return await _fetchWithRetry(url, _getBaseConfig('DELETE'))
    } catch (e) {
      _handleError(e)
    }
  }
}

const withoutHeader = /(GET|DELETE)/

const _getBaseConfig = (method) => {
  if (withoutHeader.test(method)) {
    return { method, credentials: 'include', headers: { 'Pathname': location.pathname } }
  }
  return {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Pathname': location.pathname,
    }
  }
}

const _extractResponse = async (response) => {
  const { status, headers: originalHeaders, ok, url } = response

  // prevent error if tracking is disabled on the fly (analytics intercepts the request and fails it)
  if (DISABLED_EXTRACTION.test(url)) {
    return { headers: {}, data: {} }
  }
  if (!ok) {
    const { error: { description, vicampoErrorCode: cause } } = await response.json()
    const error = new Error(description, { cause })
    error.status = status
    error.cause = cause
    throw error
  }

  const headers = _getAsPlainObject(originalHeaders)

  if (status < 200 || status > 202) return { headers, data: {} }
  const data = await _extractDataField(response)

  return { headers, data }
}

const _handleError = ({ message, status, cause }) => {
  // cause is used to reflect the vicampoErrorCode
  const e = new Error(JSON.stringify({ status, description: message }), { cause })
  e.status = status
  e.cause = cause
  throw e
}

const _extractDataField = async (response) => {
  try {
    const payload = await response.json()
    return API_V2_REGEX.test(response.url) ? payload.data : payload
  } catch (e) {
    e.message = `[${response.url}] ${e.message}`
    throw e
  }
}

const _fetchWithRetry = async (url, options, maxRetries = MAX_RETRIES, attempt = 1) => {
  try {
    return await _extractResponse(await fetch(url, options))
  } catch (e) {
    const retry = (attempt < maxRetries) && _shouldRetry(e)

    if (!retry) throw e
    // Request failed, retrying
    await new Promise(resolve => setTimeout(resolve, DELAY))
    await _fetchWithRetry(url, options, maxRetries, attempt + 1)
  }
}

const _shouldRetry = ({ description, status }) => {
  return STATUS_REGEX.test(status) || DESCRIPTION_REGEX.test(description)
}

const _getAsPlainObject = (headers) => Array.from(headers.entries())
  .reduce((collected, [key, value]) => ({ ...collected, [key]: value }), {})

export default RequestHelper
